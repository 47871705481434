import React from 'react'
import get from 'lodash/get'
import { graphql } from 'gatsby'
import HomeLayout from '../components/layouts/HomeLayout'
import Seo from '../components/seo'
import ArticleBanner from '../components/common/ArticleBanner/ArticleBanner'
import { Link, useIntl } from 'gatsby-plugin-intl'
import Badge from '../components/common/Badge/Badge'
import CardSlider from '../components/common/CardSlider/CardSlider'
import EventCard from '../components/common/EventCard/EventCard'
import Article from '../components/widgets/Article/Article'
import useLink from '../hooks/useLink'

const PageEventTemplate = props => {
  const location = typeof window !== 'undefined' ? window.location : { pathname: '', href: '' }
  const pageData = get(props, 'data.contentfulPageEvenement')
  const intl = useIntl()
  const { buildRootURL, buildURL } = useLink()

  const startDate = intl.formatDate(pageData.startDate, {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  })
  const stopDate = intl.formatDate(pageData.endDate, {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  })
  const dateDescription =
    startDate === stopDate
      ? intl.formatMessage({ id: 'article-banner.the-date' }, { date: stopDate })
      : intl.formatMessage({ id: 'article-banner.from-to-date' }, { startDate, stopDate })

  const otherEvents = get(props, 'data.otherEvents.edges', []).map(page => page.node)

  return (
    <HomeLayout location={location}>
      {pageData && (
        <>
          <Seo title={pageData.title} />
          <ArticleBanner
            title={pageData.title}
            image={pageData.image}
            location={location}
            subtitle={dateDescription}
          />
          <div className="container py-4 py-md-5">
            {(pageData.description || pageData.tags) && (
              <div className="small-content mb-3 mb-lg-5">
                {pageData.description && <div className="intro">{pageData.description}</div>}
                {pageData.tags && (
                  <ul className="list-unstyled d-flex flex-wrap">
                    {pageData.tags.map(tag => (
                      <li key={tag.id} className="mx-2 mb-2">
                        <Badge {...tag} />
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )}
            <div className="small-content">
              <Article
                body={
                  typeof pageData.article?.raw === 'string'
                    ? JSON.parse(pageData.article.raw)
                    : pageData.article?.raw
                }
              />
            </div>
          </div>
          {otherEvents.length > 0 && (
            <section className="page-section bg-light">
              <div className="container">
                <h2 className="section-title text-primary">
                  {intl.formatMessage({ id: 'our-events.other-events-title' })}
                </h2>
                <CardSlider
                  cards={otherEvents.map(event => (
                    <EventCard to={buildURL(event)} {...event} key={event.id} />
                  ))}
                />
              </div>
              <div className="text-center container pt-5">
                <Link to={buildRootURL(pageData)} className={'btn btn-outline-dark block-on-sm'}>
                  {intl.formatMessage({ id: 'our-events.see-more' })}
                </Link>
              </div>
            </section>
          )}
        </>
      )}
    </HomeLayout>
  )
}

export default PageEventTemplate

export const pageQuery = graphql`
  query PagesEventBySlug($slug: String, $locale: String) {
    site {
      siteMetadata {
        title
        description
        siteUrl
      }
    }
    contentfulPageEvenement(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      __typename
      id
      slug
      title
      type
      node_locale
      startDate
      endDate
      image {
        file {
          url
        }
        title
      }
      article {
        raw
      }
      tags {
        color
        title
      }
    }
    otherEvents: allContentfulPageEvenement(
      filter: { node_locale: { eq: $locale }, slug: { ne: $slug } }
    ) {
      edges {
        node {
          __typename
          id
          slug
          type
          node_locale
          startDate
          endDate
          title
          image {
            title
            file {
              url
            }
          }
        }
      }
    }
  }
`
